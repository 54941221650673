import React, { useState } from 'react';
import { theme } from 'theme';

import { Box, LinearProgress, Typography } from '@mui/material';

import SwiperCarousel from 'components/main/testimonials/TestimonialsCarousel';

import { ReactComponent as PenBG } from 'assets/main/testimonials/PenBG.svg';
import { ReactComponent as TestimonialsBG } from 'assets/main/testimonials/TestimonialsBG.svg';

import { testimonials } from './consts';

const TestimonialsSection = () => {
  const [progress, setProgress] = useState<number>(0);
  return (
    <Box
      id='testimonials'
      sx={{
        overflowX: 'hidden',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '1920px',
          width: '100%',
          p: '0 24px',
          pt: '140px',
          margin: 'auto',
          boxSizing: 'border-box',
          minHeight: '959px',
        }}
      >
        <TestimonialsBG
          style={{
            position: 'absolute',
            top: '94px',
            left: '565px',
            zIndex: -1,
          }}
        />
        <PenBG
          style={{
            position: 'absolute',
            top: '340px',
            left: '50px',
          }}
        />
        <Box
          sx={{
            position: 'relative',
            maxWidth: '1290px',
            width: '100%',
            margin: 'auto',
            boxSizing: 'border-box',
            minHeight: '959px',
          }}
        >
          <Typography
            sx={{
              fontSize: '64px',
              fontWeight: 800,
              lineHeight: '96px',
              textAlign: 'left',
              mb: '54px',
            }}
          >
            Testimonials and Team
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
            }}
          >
            <Box>
              <Box
                sx={{
                  minHeight: '208px',
                  width: '410px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '32px',
                    fontWeight: 700,
                    lineHeight: '48px',
                    textAlign: 'left',
                    mb: '24px',
                  }}
                >
                  Our customers trust us
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    mb: '16px',
                  }}
                >
                  At WecandevelopIT, we believe that{' '}
                  <span style={{ color: theme.palette.orange.main }}>trust is the foundation</span>{' '}
                  of every{' '}
                  <span style={{ color: theme.palette.orange.main }}>successful relationship</span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                  }}
                >
                  Our customers’ satisfaction and confidence in our services drive everything we do.
                  Here’s why our customers trust us
                </Typography>
              </Box>
              <LinearProgress
                sx={{
                  mt: '10px',
                  height: '6px',
                  border: '1px solid #eacaa9',
                  backgroundColor: 'rgba(234,202,169,0.28)',
                  borderRadius: '20px',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#FF7A01',
                    borderRadius: '20px',
                  },
                }}
                variant="determinate"
                value={progress}
              />
            </Box>
            <SwiperCarousel testimonials={testimonials} setProgress={setProgress} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TestimonialsSection;
