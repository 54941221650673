import { throttle } from 'lodash';
import { debounce } from 'lodash';
import React, { FC, useCallback, useRef, useState } from 'react';
import { theme } from 'theme';

import { Box, BoxProps } from '@mui/material';
import Typography from '@mui/material/Typography';

import { ReactComponent as Arrow } from 'assets/common/Arrow.svg';

interface IProps extends BoxProps {
  setOpenGotAProjectDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const FlyingBall: FC<IProps> = ({ setOpenGotAProjectDrawer, sx }) => {
  const parentWidth = 300;
  const ballWidth = 190;
  const centerPosition = (parentWidth - ballWidth) / 2;
  const [position, setPosition] = useState({ x: centerPosition, y: centerPosition });
  const ref = useRef<HTMLDivElement | null>(null);
  const refParent = useRef<HTMLDivElement | null>(null);

  const throttledMouseMove = useCallback(
    throttle((event: React.MouseEvent) => {
      if (ref?.current && refParent.current) {
        const parentRect = refParent.current?.getBoundingClientRect();
        const mouseX = event.clientX - parentRect.left - ref.current?.offsetWidth / 2;
        const mouseY = event.clientY - parentRect.top - ref.current?.offsetHeight / 2;
        const x = Math.max(0, Math.min(mouseX, parentRect.width - ref.current?.offsetWidth));
        const y = Math.max(0, Math.min(mouseY, parentRect.height - ref.current?.offsetWidth));
        setPosition({ x, y });
      }
    }, 100),
    []
  );

  const handleMouseMove = (event: React.MouseEvent) => {
    throttledMouseMove(event);
  };
  const handleMouseLeave = debounce(() => {
    const x = centerPosition;
    const y = centerPosition;
    setPosition({ x, y });
  }, 300);
  return (
    <Box
      ref={refParent}
      sx={{
        width: `${parentWidth}px`,
        height: `${parentWidth}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '50%',
        position: 'absolute',
        zIndex: 10,
        bottom: `${-centerPosition}px`,
        right: `${-centerPosition}px`,
        ...sx,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        onClick={() => {
          setOpenGotAProjectDrawer(true);
        }}
        ref={ref}
        sx={{
          width: `${ballWidth}px`,
          height: `${ballWidth}px`,
          backgroundColor: theme.palette.orange.main,
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          position: 'absolute',
          left: `${position.x}px`,
          top: `${position.y}px`,
          transition: '0.2s ease-out',
          cursor: 'pointer',
          transform: position.x === centerPosition ? 'scale(1)' : 'scale(0.8)',
        }}
      >
        <Arrow />
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            textAlign: 'center',
            color: theme.palette.text.secondary,
            width: '128px',
          }}
        >
          Your first consultation is free – no strings attached.
        </Typography>
      </Box>
    </Box>
  );
};

export default FlyingBall;
