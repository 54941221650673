import useJsonRepeatPlay from 'hooks/useJsonRepeatPlay';
import Lottie from 'lottie-react';
import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { HowDoWeDo } from 'pages/main/how-do-we-do/consts';

interface IProps {
  howDoWeDo: HowDoWeDo;
}

const loadJson = (path: string) => {
  return import(`assets/main/how-do-we-do/${path}`);
};
const HowDoWeDoCard: FC<IProps> = ({ howDoWeDo }) => {
  const { id, json, aboveTitle, title, descriptionTop, descriptionBottom, phrase } = howDoWeDo;
  const { animationData, isPlay, setIsPlay, setHasPlayed, handleMouseEnter } = useJsonRepeatPlay({
    json,
    loadJson,
  });
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  useEffect(() => {
    if (inView) {
      handleMouseEnter();
    }
  }, [inView]);
  return (
    <Box
      sx={{
        width: '100vw',
        minWidth: '1650px',
        height: '954px',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          minHeight: '943px',
          gap: '30px',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            fontSize: '40px',
            fontWeight: 700,
            lineHeight: '50px',
            fontFamily: 'Caveat',
            position: 'absolute',
            bottom: '150px',
            right: '140px',
            color: theme.palette.orange.main,
            zIndex: 2,
          }}
        >
          {phrase}
        </Typography>

        <Box
          sx={{
            width: '626px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '24px',
            color: theme.palette.text.primary,
            position: 'relative',
            zIndex: 2,
          }}
        >
          {id == 1 && (
            <Typography
              sx={{
                position: 'absolute',
                top: '140px',
                left: '0px',
                fontSize: '64px',
                fontWeight: 800,
                lineHeight: '96px',
                textAlign: 'left',
                mb: '54px',
                color: theme.palette.text.primary,
                zIndex: 2,
              }}
            >
              How Do We Do IT?
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: '290px',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '36px',
                textAlign: 'left',
              }}
            >
              {aboveTitle}
            </Typography>
            <Typography
              sx={{
                fontSize: '150px',
                fontWeight: 800,
                lineHeight: '174px',
                textAlign: 'left',
                color: theme.palette.orange.main,
              }}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 400,
              lineHeight: '36px',
              textAlign: 'left',
              width: '630px',
            }}
          >
            {descriptionTop}
            <br />
            <br />
            {descriptionBottom}
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            position: 'relative',
            width: '945px',
          }}
        >
          <Box ref={ref}>
            <Lottie
              onMouseEnter={() => {
                json && handleMouseEnter();
              }}
              onMouseLeave={() => {
                json && setIsPlay(false);
                json && setHasPlayed(false);
              }}
              style={{
                width: '975px',
                position: 'absolute',
                bottom: '-5px',
                left: '0px',
                cursor: 'pointer',
              }}
              animationData={animationData}
              autoplay={true}
              loop={isPlay}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowDoWeDoCard;
