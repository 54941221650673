import * as React from 'react';
import { FC } from 'react';
import { theme } from 'theme';

import { Box, Grow, IconButton, Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { IPopupInfo } from 'components/common/GotAProject';

import { ReactComponent as Close } from 'assets/common/Close.svg';

import { CustomButton } from '../Button';

interface IProps extends DialogProps {
  open: boolean;
  description: string;
  onClose: React.Dispatch<React.SetStateAction<IPopupInfo>>;
}

const SendMessagePopup: FC<IProps> = ({ open,  description, onClose,  }) => {
  return (
    <Dialog
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 300 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      open={open}
      onClose={() => onClose((prevState) => ({ ...prevState, open: false }))}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: '423px',
          width: '100%',
          minHeight: '168px',
          boxSizing: 'border-box',
          display: 'flex',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          alignItems: 'center',
          m: 0,
        },
        onClick: (e: any) => {
          e.stopPropagation();
          onClose((prevState) => ({ ...prevState, open: false }));
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          maxWidth: '423px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.text.primary,
            borderRadius: '20px',
            maxWidth: '363px',
            width: '100%',
            minHeight: '168px',
            p: '24px',
            gap: '24px',
            boxSizing: 'border-box',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '14px', md: '18px' },
              fontWeight: { xs: 500 },
              lineHeight: { xs: '21px', sm: '21px', md: '27px' },
              textAlign: 'left',
              color: theme.palette.text.secondary,
            }}
          >
            {description} !!!!!!!!!!!!!!!!!!!!!!!!!!!!
          </Typography>
          <CustomButton
            sx={{
              width: '150px',
              height: '48px',
              borderRadius: '30px',
              backgroundColor: theme.palette.text.secondary,
              color: theme.palette.text.primary,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            Ok
          </CustomButton>
        </Box>
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '14px', md: '18px' },
            fontWeight: { xs: 500 },
            lineHeight: { xs: '21px', sm: '21px', md: '27px' },
            textAlign: 'left',
            color: theme.palette.text.secondary,
            maxWidth: { xs: '276px', sm: '364px' },
          }}
        >
          {description}
        </Typography>

        <IconButton
          color="secondary"
          aria-label="close"
          onClick={(e) => {
            e.stopPropagation();

            onClose((prevState) => ({ ...prevState, open: false }));
          }}
          sx={{
            alignSelf: 'start',
            width: '48px',
            height: '48px',
            backgroundColor: theme.palette.text.secondary,
            '&:hover': {
              backgroundColor: theme.palette.text.secondary,
            },
            '&:active': {
              backgroundColor: theme.palette.text.secondary,
            },
          }}
        >
          <Close
            stroke={theme.palette.text.primary}
            onClick={(e) => {
              e.stopPropagation();
              onClose((prevState) => ({ ...prevState, open: false }));
            }}
            style={{
              cursor: 'pointer',
            }}
          />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default SendMessagePopup;
