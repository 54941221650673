import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';

import {Error} from './error';
import {NotFound} from './not-found';
import Root from './root';
import MainPage from "./main";
import MainLayout from "components/main-layout";
import PortfolioPage from './portfolio';
import NewsPage from './news';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root/>} errorElement={<Error/>}>
      <Route element={<MainLayout/>}>
        <Route index element={<MainPage/>}/>
        <Route path="/portfolio" element={<PortfolioPage/>}/>
        <Route path="/news" element={<NewsPage/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Route>
    </Route>
  )
);

export default router;
