import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import { TextPlugin } from 'gsap/dist/TextPlugin';
import React from 'react';

import { Box } from '@mui/material';

import AwardsSection from './awards';
import { awardsAnimation } from './awards/animation';
import DreamSection from './dream';
import { dreamAnimation } from './dream/animation';
import FirstSection from './first';
import HowWeDoWeDoIt from './how-do-we-do';
import { howDoWeDoAnimation } from './how-do-we-do/animation';
import NewsSection from './news';
import { newsAnimation } from './news/animation';
import PortfolioSection from './portfolio';
import { portfolioAnimation } from './portfolio/animation';
import ProcessSection from './process';
import { processAnimation } from './process/animation';
import Services from './services';
import { servicesAnimation } from './services/animation';
import TestimonialsSection from './testimonials';
import WhoWeAre from './who-we-are';
import { whoWeAreAnimation } from './who-we-are/animation';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(TextPlugin);
gsap.registerPlugin(useGSAP);
const MainPage = () => {
  useGSAP(() => {
    servicesAnimation();
    howDoWeDoAnimation();
    processAnimation();
    portfolioAnimation();
    whoWeAreAnimation();
    awardsAnimation();
    newsAnimation();
    dreamAnimation();
  });
  return (
    <Box>
      <FirstSection />
      <Services />
      <HowWeDoWeDoIt />
      <ProcessSection />
      <PortfolioSection />
      <TestimonialsSection />
      <WhoWeAre />
      <AwardsSection />
      <NewsSection />
      <DreamSection />
    </Box>
  );
};

export default MainPage;
