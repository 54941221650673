interface IPage {
  text: string;
  link: string;
}

export const appbarLinks: IPage[] = [
  { text: 'Services', link: 'services' },
  { text: 'Process', link: 'process' },
  { text: 'Portfolio', link: 'portfolio' },
];
export const appbarDropdownLinks: IPage[] = [
  { text: 'Testimonials and Team', link: 'testimonials' },
  { text: 'How we are?', link: 'whoweare' },
  { text: 'Awards and Trends', link: 'awards' },
  { text: 'Blog and news', link: 'news' },
];

export const sections = [
  { id: 'first', title: 'Home' },
  { id: 'services', title: 'Services' },
  { id: 'howdowedo', title: 'How Do We Do IT' },
  { id: 'process', title: 'Process' },
  { id: 'portfolio', title: 'Portfolio' },
  { id: 'testimonials', title: 'Testimonials and Team' },
  { id: 'whoweare', title: 'How we are?' },
  { id: 'awards', title: 'Awards and Trends' },
  { id: 'news', title: 'Blog and news' },
];
