import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';

import styles from './styles.module.scss';

import { Box, Typography } from '@mui/material';

import ServicesCard from 'components/main/services/ServicesCard';

import CircletPng from 'assets/main/services/Circlet.png';
import CircletLeft from 'assets/main/services/CircletLeft.png';
import CircletRight from 'assets/main/services/CircletRight.png';
import { ReactComponent as Services } from 'assets/main/services/Services.svg';
import TriangularPng from 'assets/main/services/Triangular.png';
import TriangularLeftPng from 'assets/main/services/TriangularLeft.png';
import TriangularRightPng from 'assets/main/services/TriangularRight.png';

import { services } from './consts';

const ServicesSection = () => {
  const { smXl, xlUp } = useMediaSize();

  return (
    <Box
      id='services'
      sx={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        className="services_section"
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: '2016px',
          py: '140px',
          px: '24px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Services
          style={{
            position: 'absolute',
            top: '16px',
            left: '0px',
            maxWidth: '1389px',
            width: '100%',
            zIndex: -1,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '165px',
            right: '-50px',
            display: { xs: 'block', xxl: 'none' },
          }}
        >
          <Box
            component="img"
            src={TriangularPng}
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              zIndex: -1,
            }}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '935px',
            left: '315px',
            display: { xs: 'block', xxl: 'none' },
          }}
        >
          <Box
            component="img"
            src={CircletPng}
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',
              zIndex: -1,
              transform: 'rotate(-33deg)',
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: '-140px',
              left: '-371px',
              display: { xs: 'none', xxl: 'block' },
            }}
          >
            <Box
              component="img"
              src={CircletLeft}
              sx={{
                position: 'absolute',
                bottom: '-95px',
                left: '182px',
                zIndex: 1,
                transform: 'rotate(-32deg)',
              }}
            />
            <Box
              component="img"
              src={CircletRight}
              sx={{
                position: 'absolute',
                bottom: '78px',
                left: '248px',
                transform: 'rotate(-32deg)',

                zIndex: -1,
              }}
            />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '22px',
              right: '-150px',
              display: { xs: 'none', xxl: 'block' },
            }}
          >
            <Box
              component="img"
              src={TriangularLeftPng}
              sx={{
                position: 'absolute',
                top: '15px',
                right: '75px',
                zIndex: -1,
              }}
            />
            <Box
              component="img"
              src={TriangularRightPng}
              sx={{
                position: 'absolute',
                top: '-14px',
                right: '-13px',
                zIndex: 1,
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: '64px',
              fontWeight: 800,
              lineHeight: '96px',
              textAlign: 'left',
              mb: '54px',
            }}
          >
            Services
          </Typography>
          <Box
            className={
              xlUp ? styles.gridWrapperXL : smXl ? styles.gridWrapperSM : styles.gridWrapperXS
            }
          >
            {services.map((service) => (
              <Box
                key={service.id}
                className={`${styles[`services_card_${service.id}`]} services_card_${service.id}`}
              >
                <ServicesCard key={service.id} services={service} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServicesSection;
