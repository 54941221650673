import React from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import PortfolioCard from 'components/main/portfolio/PortfolioCard';

import { ReactComponent as Pen } from 'assets/main/porfolio/Pen.svg';
import { ReactComponent as Portfolio } from 'assets/main/porfolio/Portfolio.svg';
import { ReactComponent as Ruler } from 'assets/main/porfolio/Ruler.svg';

import { portfolioBottomRow, portfolioTopRow } from 'pages/portfolio/consts';
import { useNavigate } from 'react-router-dom';

const PortfolioSection = () => {
  const navigate = useNavigate();
  return (
    <Box
      id="portfolio"
      className="portfolio_section"
      sx={{
        overflowX: 'hidden',
        width: '100%',
        backgroundColor: theme.palette.black.main,
        p: '0 24px',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '1920px',
          width: '100%',
          minHeight: '1460px',
          p: '140px 0 140px 0',
          margin: 'auto',
          boxSizing: 'border-box',
          backgroundColor: theme.palette.black.main,
        }}
      >
        <Portfolio
          style={{
            maxWidth: '1070px',
            width: '100%',
            height: 'auto',
            position: 'absolute',
            top: '69px',
            left: '52%',
            transform: 'translateX(-50%)',
          }}
        />
        <Ruler
          style={{
            position: 'absolute',
            top: '450px',
            left: '230px',
            transform: 'translateX(-50%)',
          }}
        />
        <Pen
          style={{
            position: 'absolute',
            bottom: '218px',
            right: '-190px',
            transform: 'translateX(-50%)',
          }}
        />
        <Box
          sx={{
            minHeight: '1180px',
          }}
        >
          <Typography
            sx={{
              fontSize: '64px',
              fontWeight: 800,
              lineHeight: '96px',
              textAlign: 'left',
              color: theme.palette.text.primary,
              maxWidth: '1290px',
              margin: '0 auto',
              mb: '54px',
            }}
          >
            Portfolio
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <Box className="portfolio_section_top_row" sx={{ display: 'flex', gap: '30px' }}>
              {portfolioTopRow.map((card) => (
                <PortfolioCard key={card.id} portfolio={card} />
              ))}
            </Box>
            <Box
              className="portfolio_section_bottom_row"
              sx={{ display: 'flex', gap: '30px', justifyContent: 'end' }}
            >
              {portfolioBottomRow.map((card) => (
                <PortfolioCard key={card.id} portfolio={card} />
              ))}
            </Box>
          </Box>
          <CustomButton
            sx={{
              margin: 'auto',
              mt: '32px',
              width: '182px',
              height: '56px',
              borderRadius: '30px',
              backgroundColor: theme.palette.orange.main,
              color: theme.palette.text.secondary,
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
            onClick={()=>navigate('/portfolio')}
          >
            View all projects
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioSection;
