import { Portfolio } from 'types';
import Accuscore from 'assets/main/porfolio/Accuscore.webp'
import CleverWallet from 'assets/main/porfolio/CleverWallet.webp'
import MetaAds from 'assets/main/porfolio/MetaAds.webp'
import Reconnect from 'assets/main/porfolio/Reconnect.webp'
import Shopiview from 'assets/main/porfolio/Shopiview.webp'
import Ticombo from 'assets/main/porfolio/Ticombo.webp'
import Tixnet from 'assets/main/porfolio/Tixnet.webp'
import CargoFlowers from 'assets/main/porfolio/CargoFlowers.webp'
import EPICompliance from 'assets/main/porfolio/EPICompliance.webp'
import TrustRatings from 'assets/main/porfolio/TrustRatings.webp'
import Epik from 'assets/main/porfolio/Epik.webp'
import Locolabs from 'assets/main/porfolio/Locolabs.webp'
import Fastr from 'assets/main/porfolio/Fastr.webp'
import Kolyom from 'assets/main/porfolio/Kolyom.webp'
import SwissBiometrix from 'assets/main/porfolio/SwissBiometrix.webp'
import RegistrarSoftware from 'assets/main/porfolio/RegistrarSoftware.webp'
import EximFlowers from 'assets/main/porfolio/EximFlowers.webp'
import Orkistra from 'assets/main/porfolio/Orkistra.webp'

export const portfolioTopRow : Portfolio[] = [
  {
    id:1,
    title:'Reconnect AI',
    description:'БЛАБЛАБЛА',
    image:Reconnect
  },
  {
    id:2,
    title:'Ticombo',
    description:'For Ticombo, we developed a transparent and user-friendly ticket marketplace. The platform supports multiple languages and currencies, offering customized features for local users. ',
    image:Ticombo
  },
  {
    id:3,
    title:'Clever wallet',
    description:'БЛАБЛАБЛА',
    image:CleverWallet
  },
  {
    id:4,
    title:'Meta Ads',
    description:'We built a complete marketing platform for Meta Ads, leveraging the Near blockchain. This innovative platform is designed for executing and analyzing marketing campaigns within the metaverse. ',
    image:MetaAds
  },
  {
    id:5,
    title:'Tixnet',
    description:'We developed the Tixnet platform, a primary ticketing solution for event organizers. Tixnet stands out as the first global marketplace offering a free multilingual and multi-currency platform. ',
    image:Tixnet
  },
  {
    id:6,
    title:'Shopiview',
    description:'We developed Shopiview, a startup platform that allows users to leave paid reviews on various shopping sites. ',
    image:Shopiview
  },
]

export const portfolioBottomRow : Portfolio[] = [
   {
    id:7,
    title:'Accuscore',
    description:'For Accuscore, a company specializing in sports forecasting and analytics, we delivered a full turnkey development cycle. ',
    image:Accuscore
  },
  {
    id:8,
    title:'Cargo flowers',
    description:'Mobile personal Account of the client of the service for the transportation of flowers around the world, where the user can independently check or track the delivery of goods online.',
    image:CargoFlowers
  },
  {
    id:9,
    title:'EPICompliance',
    description:'For EPICompliance, we created an online platform that simplifies HIPAA compliance for healthcare organizations. ',
    image:EPICompliance
  },
  {
    id:10,
    title:'Trust Ratings',
    description:'We created a comprehensive system for TrustRatings, designed to collect and analyze domain data. ',
    image:TrustRatings
  },
  {
    id:11,
    title:'Epik',
    description:'For Epik.com, a leading provider of domain management solutions, we developed an integrated full-service domain management system. ',
    image:Epik
  },
  {
    id:12,
    title:'Locolabs',
    description:'For Loco Labs, we developed several custom solutions, including the Show Producer application. This tool enables local advertising campaigns across various user-owned devices. ',
    image:Locolabs
  },
]

export const portfolioThirdRow : Portfolio[] = [
  {
    id:13,
    title:'Fastr',
    description:'Our goal was to help to quickly develop a concept allowing the customer to start a new round of negotiations with investors.',
    image:Fastr
  },
  {
    id:14,
    title:'Kolyom',
    description:'Kolyom is an Israeli platform for website promotion based on Big Data analysis. We developed tools for enhancing organic traffic, improving search engine rankings, and generating leads. ',
    image:Kolyom
  },
  {
    id:15,
    title:'Swiss Biometrix',
    description:'Our team developed a distributed access control system for Swiss Bio Metrix, managing access to various territories, buildings, and premises. ',
    image:SwissBiometrix
  },
  {
    id:16,
    title:'Registrar software',
    description:'This system, designed and built by our team, is the Swiss bank of domains. It is a world leading system that works directly with various registrars to support all domain TLDs. ',
    image:RegistrarSoftware
  },
  {
    id:16,
    title:'EximFlowers',
    description:'Project on automation of business processes related to the purchase and delivery of flowers from the manufacturer to the end customer. ',
    image:EximFlowers
  },
  {
    id:18,
    title:'Orkistra',
    description:'Wlt is a project management system that is unique in that it supports the addition of initiative and idea development. ',
    image:Orkistra
  },
]