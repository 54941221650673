import gsap from 'gsap';

export const newsAnimation = () => {
  gsap.set('.news_card_img', {
    xPercent: -100,
  });
  document.querySelectorAll('.news_card_img').forEach((el) => {
    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      duration: 0.4,
    });
  });
};
