import React from 'react';

import { Box } from '@mui/material';

import { ReactComponent as WUpper } from 'assets/main/who-we-are/WW.svg';
import { ReactComponent as WWA } from 'assets/main/who-we-are/WhoWeAre.svg';
import WhoWeAreFifth from 'assets/main/who-we-are/WhoWeAreFifth.webp';
import WhoWeAreFirst from 'assets/main/who-we-are/WhoWeAreFirst.webp';
import WhoWeAreFourth from 'assets/main/who-we-are/WhoWeAreFourth.webp';
import WhoWeAreSecond from 'assets/main/who-we-are/WhoWeAreSecond.webp';
import WhoWeAreThird from 'assets/main/who-we-are/WhoWeAreThird.webp';
import { ReactComponent as A } from 'assets/main/who-we-are/a.svg';
import { ReactComponent as E } from 'assets/main/who-we-are/e.svg';
import { ReactComponent as H } from 'assets/main/who-we-are/h.svg';
import { ReactComponent as O } from 'assets/main/who-we-are/o.svg';
import { ReactComponent as R } from 'assets/main/who-we-are/r.svg';
import { ReactComponent as W } from 'assets/main/who-we-are/w.svg';

const WhoWeAre = () => {
  return (
    <Box
      id="whoweare"
      className="wwa_section"
      sx={{
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          overflowX: 'hidden',
          maxWidth: '100%',
          minHeight: '979px',
          width: '100%',
          margin: 'auto',
          p: '80px 0',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <WWA
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}
        />
        <Box
          sx={{
            boxSizing: 'border-box',
            width: '3840px',
            position: 'relative',
            display: 'flex',
            gap: '178px',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            className="wwa_section_text"
            sx={{
              display: 'flex',
              alignItems: 'end',
              margin: 'auto',
              width: '3840px',
              height: '411px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              zIndex: -1,
              ml: '170px',
            }}
          >
            <WUpper style={{ marginRight: '51px' }} />
            <H style={{ marginRight: '62px' }} />
            <O style={{ marginRight: '164px', zIndex: 3 }} />
            <W style={{ marginRight: '26px' }} />
            <E style={{ marginRight: '178px' }} />
            <A style={{ marginRight: '82px' }} />
            <R style={{ marginRight: '35px' }} />
            <E />
          </Box>

          <Box
            className="wwa_section_photo"
            sx={{
              display: 'flex',
              alignItems: 'center',
              zIndex: 1,
              width: '3840px',
            }}
          >
            <Box component="img" src={WhoWeAreFirst} sx={{ ml: '100px' }} />
            <Box component="img" src={WhoWeAreSecond} sx={{ ml: '1295px' }} />
            <Box component="img" src={WhoWeAreThird} sx={{ ml: '1210px' }} />
          </Box>

          <Box
            className="wwa_section_photo"
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              margin: 'auto',
              width: '3840px',
            }}
          >
            <Box component="img" src={WhoWeAreFourth} />
            <Box component="img" src={WhoWeAreFifth} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WhoWeAre;
