import gsap from 'gsap';

export const awardsAnimation = () => {
  gsap.set(
    [
      '.awards_card_1',
      '.awards_card_2',
      '.awards_card_3',
      '.awards_card_4',
      '.awards_card_5',
      '.awards_card_6',
      '.awards_card_7',
      '.awards_card_8',
      '.awards_card_9',
      '.awards_card_10',
      '.awards_card_11',
    ],
    {
      opacity: 0,
      scale: 0,
    }
  );
  gsap.fromTo(
    '.awards_card_1',
    {
      xPercent: -75,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_1',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_2',
    {
      xPercent: 0,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_2',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_3',
    {
      xPercent: 75,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_3',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );

  gsap.fromTo(
    '.awards_card_4',
    {
      xPercent: -50,
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_4',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_5',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_5',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_6',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_6',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_7',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_7',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_8',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_8',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_9',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_9',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_10',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_10',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
  gsap.fromTo(
    '.awards_card_11',
    {
      yPercent: 50,
    },
    {
      scrollTrigger: {
        trigger: '.awards_card_11',
        start: '100 bottom',
        end: 'bottom center',
        toggleActions: 'play none none reverse',
        invalidateOnRefresh: true,
      },
      xPercent: 0,
      yPercent: 0,
      opacity: 1,
      scale: 1,
    }
  );
};
