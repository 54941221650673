import useJsonRepeatPlay from 'hooks/useJsonRepeatPlay';
import Lottie from 'lottie-react';
import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { Awards } from 'pages/main/awards/consts';

interface IProps {
  award: Awards;
}

const loadJson = (path: string) => {
  return import(`assets/main/awards/${path}`);
};
const AwardsCard: FC<IProps> = ({ award }) => {
  const {
    title,
    description,
    image,
    json,
    imageBoxSX,
    textBoxSX,
    mainBoxSX,
    jsonStyle,
    textSX,
    textTitleSX,
  } = award;
  const { animationData, isPlay, setIsPlay, setHasPlayed, handleMouseEnter } = useJsonRepeatPlay({
    json,
    loadJson,
  });
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  useEffect(() => {
    if (inView) {
      handleMouseEnter();
    }
  }, [inView]);
  return (
    <Box
      onMouseEnter={() => {
        json && handleMouseEnter();
      }}
      onMouseLeave={() => {
        json && setIsPlay(false);
        json && setHasPlayed(false);
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '300px',
        width: '100%',
        height: '374px',
        overflow: 'hidden',
        position: 'relative',
        p: '24px',
        boxSizing: 'border-box',
        borderRadius: '20px',
        cursor: 'pointer',
        '&:hover': {
          transform: `scale(1.05)`,
          zIndex: 11,
        },
        backgroundColor: '#1C1C1C',
        border: '1px solid #FFFFFF1A',
        ...mainBoxSX,
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <Box sx={{ color: theme.palette.text.primary, gap: '8px', ...textBoxSX }}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            lineHeight: '48px',
            textAlign: 'left',
            position: 'relative',
            zIndex: 10,
            ...textTitleSX,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
            position: 'relative',
            zIndex: 10,
            ...textSX,
          }}
        >
          {description}
        </Typography>
      </Box>
      {image && <Box component="img" src={image} sx={imageBoxSX} />}
      {json && (
        <Box ref={ref}>
          <Lottie
            style={{ zIndex: 1, ...jsonStyle }}
            animationData={animationData}
            autoplay={true}
            loop={isPlay}
          />
        </Box>
      )}
    </Box>
  );
};

export default AwardsCard;
