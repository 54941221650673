import { SxProps } from '@mui/material';

import ArsenalHost from 'assets/main/news/ArsenalHost.webp';
import CheckOut from 'assets/main/news/CheckOut.webp';
import CleverWallet from 'assets/main/news/CleverWallet.webp';
import GlobalGamers from 'assets/main/news/GlobalGamers.webp';
import ReconnectAI from 'assets/main/news/ReconnectAI.webp';
import Today from 'assets/main/news/Today.webp';

export interface News {
  id: number;
  image: string;
  title: string;
  createdAt: string;
  newsType: string;
  imageBoxSX?: SxProps;
  mainBoxSX?: SxProps;
}

export const news: News[] = [
  {
    id: 1,
    title: 'Arsenal Host: Fixing All WordPress Issues for Free!',
    newsType: 'Tech News',
    createdAt: 'November 10,2024',
    image: ArsenalHost,
    imageBoxSX: { width: '100%', height: '290px' },
  },
  {
    id: 2,
    title: 'Today, Our Website Celebrates Its Launch!',
    newsType: 'IT Development',
    createdAt: 'October 25,2024',
    image: Today,
    mainBoxSX: { marginTop: { xl: '52px' } },
    imageBoxSX: { width: '100%', height: '186px' },
  },
  {
    id: 3,
    title: 'Reconnect AI: Bridging the Past and  Present with Interactive AI Avatars',
    newsType: 'Artificial Intelligence',
    createdAt: 'September 15,2024',
    image: ReconnectAI,
    imageBoxSX: { width: '100%', height: '186px' },
  },
  {
    id: 4,
    title: 'Check Out Our New YouTube Channel for  Fun and IT News!',
    newsType: 'Tech News',
    createdAt: 'July 25,2024',
    image: CheckOut,
    mainBoxSX: { marginTop: { xl: '100px' } },
    imageBoxSX: { width: '100%', height: '186px' },
  },
  {
    id: 5,
    title: 'Clever Wallet: Your All-in-One Solution for  Financial Management',
    newsType: 'Fintech Solution',
    createdAt: 'June 25,2024',
    image: CleverWallet,
    mainBoxSX: { marginTop: { xl: '139px' } },
    imageBoxSX: { width: '100%', height: '186px' },
  },
  {
    id: 6,
    title: 'Global Gamers Challenge Hack: How We  Created a Game to Help the Planet',
    newsType: 'Hackathon Project',
    createdAt: 'May 15,2024',
    image: GlobalGamers,
    imageBoxSX: { width: '100%', height: '290px' },
  },
];
