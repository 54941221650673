import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { FC, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

import { Box, Drawer, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { CustomButton } from 'components/common/Button';
import { CustomCheckbox } from 'components/common/Checkbox';
import SendMessagePopup from 'components/common/SendMessagePopup';
import TextInput from 'components/common/TextInput';

import { ReactComponent as Close } from 'assets/common/Close.svg';

import { subjects } from './consts';
import { CONTACT_SCHEMA } from './validation';

interface FormValues {
  name: string;
  email: string;
  phone: string;
  company?: string;
  country?: string;
  message: string;
  accept: boolean;
}

export interface IPopupInfo {
  open: boolean;
  title: string;
  description: string;
}

interface IProps {
  openGotAProjectDrawer: boolean;
  setOpenGotAProjectDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GotAProjectDrawer: FC<IProps> = ({
  openGotAProjectDrawer,
  setOpenGotAProjectDrawer,
}) => {
  const [popupInfo, setPopupInfo] = useState<IPopupInfo>({
    open: false,
    title: '',
    description: '',
  });
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [isDisabledCaptcha, setIsDisabledCaptcha] = useState<boolean>(false);
  const [selectedSubjects, setSelectedSubjects] = useState<number[]>([]);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(CONTACT_SCHEMA),
    mode: 'onBlur',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      company: '',
      country: '',
      accept: false,
    },
  });
  const onSubmit = async (contacts: FormValues) => {
    try {
      if (recaptchaToken) {
        setIsDisabledButton(true);
        const dataToSend = {
          name: contacts.name,
          email: contacts.email,
          phone: contacts.phone,
          message: contacts.message,
        };
        const formData = new FormData();
        Object.keys(dataToSend).forEach((key) => {
          const value = dataToSend[key as keyof typeof dataToSend];
          if (typeof value !== 'undefined') {
            formData.append(key, value);
          }
        });
        formData.append('g-recaptcha-response', recaptchaToken);
        await axios.post('https://www.reconnectai.org/sendmail/sendemail.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setIsDisabledButton(false);
        reset();
        setSelectedSubjects([])
        setPopupInfo({
          open: true,
          title: 'Form submitted',
          description: 'Thank you for contacting us. We will contact you shortly',
        });
        reset();
        setSelectedSubjects([])
      } else {
        setIsDisabledCaptcha(true);
      }
    } catch (error: any) {
      setIsDisabledButton(false);
      if (axios.isAxiosError(error) && error?.response) {
        const messageError = error.response?.data?.message;
        if (messageError === 'Captcha not completed, please try again') {
          setRecaptchaToken(null);
          setIsDisabledCaptcha(true);
        } else {
          setPopupInfo({
            open: true,
            title: 'Form not submitted',
            description: messageError || 'Please try again later',
          });
        }
      } else {
        setPopupInfo({
          open: true,
          title: 'Form not submitted',
          description: 'Please try again later',
        });
      }
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/[^+\d]/g, '').replace(/(?!^)\+/g, '');

    setValue('phone', filteredValue);
  };
  return (
    <Drawer
      open={openGotAProjectDrawer}
      onClose={() => {
        setOpenGotAProjectDrawer(false);
        reset();
        setSelectedSubjects([])
      }}
      anchor="right"
      sx={{
        '.MuiDrawer-paper': {
          maxWidth: '608px',
          width: '100%',
          backgroundColor: theme.palette.black.main,
          backgroundImage: 'none',
        },
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 800,
              lineHeight: '65px',
              textAlign: 'left',
            }}
          >
            Got a <span style={{ color: theme.palette.orange.main }}> project</span> ?
          </Typography>
          <IconButton
            edge="end"
            size="large"
            aria-label="close"
            onClick={() => {
              setOpenGotAProjectDrawer(false);
            }}
            sx={{
              width: '48px',
              height: '48px',
              backgroundColor: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.text.primary,
              },
              '&:active': {
                backgroundColor: theme.palette.text.primary,
              },
            }}
          >
            <Close stroke={theme.palette.text.secondary} />
          </IconButton>
        </Box>

        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '27,32px',
            textAlign: 'left',
          }}
        >
          Share the details of your project – like scope, timeframes, or business challenges. Our
          team will thoroughly review the materials and respond to you promptly.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 400,
              lineHeight: '27,32px',
              textAlign: 'left',
            }}
          >
            {"I'm interested in"}
          </Typography>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            {subjects.map((tag) => (
              <Typography
                key={tag.id}
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '21px',
                  textAlign: 'left',
                  border: '1px solid #B0B5B7',
                  borderRadius: '73px',
                  p: '6px 16px',
                  color: selectedSubjects.includes(tag.id)
                    ? theme.palette.text.secondary
                    : theme.palette.text.primary,
                  backgroundColor: selectedSubjects.includes(tag.id)
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (selectedSubjects.includes(tag.id)) {
                    setSelectedSubjects((prev) => prev.filter((item) => item !== tag.id));
                  } else {
                    setSelectedSubjects((prev) => [...prev, tag.id]);
                  }
                }}
              >
                {tag.title}
              </Typography>
            ))}
          </Box>
        </Box>

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            flex: 1,
            maxWidth: { xs: '100%', sm: '642px' },
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: { xs: 'center', sm: 'start' },
            gap: '16px',
          }}
        >
          <TextInput name="name" label="Full Name*" errors={errors} control={control} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '12px',
              width: '100%',
            }}
          >
            <TextInput name="email" label="E-mail*" errors={errors} control={control} />
            <TextInput
              name="phone"
              label="Phone*"
              errors={errors}
              control={control}
              onChangeHandler={handleChange}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '12px',
              width: '100%',
            }}
          >
            <TextInput name="company" label="Company" errors={errors} control={control} />
            <TextInput name="country" label="Country or City" errors={errors} control={control} />
          </Box>
          <TextInput name="message" label="Message*" errors={errors} control={control} multiline />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <CustomCheckbox
              name="accept"
              label="I accept the Terms"
              errors={errors}
              control={control}
            />
            <CustomButton
              sx={{
                margin: 'auto',
                width: '100%',
                height: '56px',
                borderRadius: '30px',
                backgroundColor: theme.palette.orange.main,
                color: theme.palette.text.secondary,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
              }}
              type="submit"
              disabled={isDisabledButton}
            >
              Send message{' '}
            </CustomButton>
            <Typography
              sx={{
                textDecoration: 'none',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'left',
              }}
            >
              By pressing “Send” button you confirm that you have read and accept our
              <Link
                to="/privacy-policy"
                style={{
                  textDecoration: 'none',
                  margin: '0 3px',
                  color: theme.palette.primary.main,
                }}
              >
                Privacy Policy
              </Link>
              and
              <Link
                to="/terms-conditions"
                style={{
                  textDecoration: 'none',
                  margin: '0 3px',
                  color: theme.palette.primary.main,
                }}
              >
                Terms & Conditions.
              </Link>
            </Typography>
          </Box>

          <Box
            sx={{
              display: { xs: 'column', md: 'flex' },
              gap: '10px',
              justifyContent: 'space-between',
              width: '100%',
            }}
          ></Box>
        </Box>
        <Dialog
          open={isDisabledCaptcha && !recaptchaToken}
          onClose={() => setIsDisabledCaptcha(false)}
          PaperProps={{
            sx: {
              width: '298px',
              height: '73px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              m: 0,
              p: 0,
            },
          }}
        >
          <ReCAPTCHA sitekey="32423423423423" onChange={(e) => setRecaptchaToken(e)} size="normal" />
        </Dialog>
        <SendMessagePopup
          open={popupInfo.open}
          description={popupInfo.description}
          onClose={setPopupInfo}
        />
      </Box>
    </Drawer>
  );
};
