import { FC } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { PopupInfo } from 'pages/main/services/consts';

interface IProps {
  title: string;
  popupInfo: PopupInfo;
}

const PopupContentBox: FC<IProps> = ({ popupInfo, title }) => {
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '655px',
        width: '100%',
        minHeight: '754px',
        p: '24px',
        boxSizing: 'border-box',
        backgroundColor:
          popupInfo?.colorMode === 'light' ? theme.palette.text.primary : theme.palette.black.main,
        borderRadius: '20px',
        position: 'relative',
      }}
    >
      <Box component="img" src={popupInfo?.popupImage} sx={{ ...popupInfo?.popupImageSX }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          zIndex: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 800,
            lineHeight: '48px',
            textAlign: 'center',
            color:
              popupInfo?.colorMode === 'light'
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
          }}
        >
          {popupInfo.title ? popupInfo.title : title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ...popupInfo.popupTextSX,
          }}
        >
          {popupInfo?.popupDescription.map((item, ind) => (
            <Box
              key={ind}
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <span style={{ color: theme.palette.orange.main }}>•</span>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'left',
                  color:
                    popupInfo?.colorMode === 'light'
                      ? theme.palette.text.secondary
                      : theme.palette.text.primary,
                }}
              >
                {item}
                <br />
                <br />
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          zIndex: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            textAlign: 'left',
            color:
              popupInfo?.colorMode === 'light'
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
          }}
        >
          Technologies:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
          }}
        >
          {popupInfo?.popupTechnologies.map((item) => (
            <Box
              key={item.popupTechnologiesText}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                maxWidth: '190px',
                width: '100%',
                p: '8px',
                boxSizing: 'border-box',
                backgroundColor: '#F5F5F5',
                borderRadius: '50px',
              }}
            >
              <Box
                component="img"
                src={item.popupTechnologiesIcon}
                // sx={{
                //   width: item?.width ? `${item.width}px` : '20px',
                //   height: item?.height ? `${item.height}px` : 'auto',
                // }}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: theme.palette.text.secondary,
                }}
              >
                {item.popupTechnologiesText}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PopupContentBox;
