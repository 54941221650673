import React, { useEffect, useState } from 'react';
import {Outlet} from 'react-router-dom'
import BlockchainDevelopment from 'assets/main/services/BlockchainDevelopment.webp';
import BotsAndAI from 'assets/main/services/BotsAndAI.webp';
import CRM from 'assets/main/services/CRM.webp';
import ECommerce from 'assets/main/services/ECommerce.webp';
import ITSupportAndMaintenance from 'assets/main/services/ITSupportAndMaintenance.webp';
import MobileWebApplications from 'assets/main/services/MobileWebApplications.webp';
import Construct from 'assets/main/process/Construct.png';
import Deploy from 'assets/main/process/Deploy.png';
import Design from 'assets/main/process/Design.png';
import Discover from 'assets/main/process/Discover.png';
import Invent from 'assets/main/process/Invent.png';
import {Box} from '@mui/material'
import { Footer } from '../common/Footer';
import CustomAppBar from '../common/AppBar';
const MAIN_IMAGES_DESKTOP: string[] = [
  BlockchainDevelopment,
  BotsAndAI,
  CRM,
  ECommerce,
  ITSupportAndMaintenance,
  MobileWebApplications,
  Construct,
  Deploy,
  Design,
  Discover,
  Invent
];
const SECONDARY_IMAGES_DESKTOP: string[] = [

];

const MainLayout = () => {
  const [shouldRender, setShouldRender] = useState(false);
  const preloadImages = (imageUrls: string[]): Promise<void[]> => {
    const promises = imageUrls.map((url) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = () => {
          console.error(`Failed to load image: ${url}`);
          resolve();
        };
      });
    });
    return Promise.all(promises);
  };
  useEffect(() => {
      (async () => {
        await preloadImages(MAIN_IMAGES_DESKTOP);
        setShouldRender(true);
        await preloadImages(SECONDARY_IMAGES_DESKTOP);
      })();
    }, []);
  if (!shouldRender) {
    return  null;
  }
  return (
    <Box>
      <CustomAppBar/>
      <Outlet/>
      <Footer/>
    </Box>
  )
}

export default MainLayout
