import React, { Dispatch, FC, SetStateAction } from 'react';
import { theme } from 'theme';

import { Box, Grow, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import { PopupInfo } from 'pages/main/services/consts';

import { ReactComponent as Close } from 'assets/common/Close.svg';

import PopupContentBox from './PopupContentBox';

interface IProps {
  setIsModal: Dispatch<SetStateAction<boolean>>;
  isModal: boolean;
  title: string;
  popupInfo: PopupInfo;
  popupInfoSecond?: PopupInfo;
}

const ServicePopup: FC<IProps> = ({ setIsModal, isModal, title, popupInfo, popupInfoSecond }) => {
  console.log(isModal);
  return (
    <Dialog
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 300 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      open={isModal}
      onClose={() => {
        setIsModal(false);
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: '100vw',
          width: '100%',
          minHeight: '754px',
          boxSizing: 'border-box',
          display: 'flex',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          alignItems: 'center',
          m: 0,
        },
        onClick: (e: any) => {
          e.stopPropagation();
          setIsModal(false);
        },
      }}
    >
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Box sx={{ display: 'flex', flexDirection: { md: 'column', xxxl: 'row' }, gap: '24px' }}>
          {popupInfoSecond && <PopupContentBox popupInfo={popupInfoSecond} title={title} />}
          <PopupContentBox popupInfo={popupInfo} title={title} />
        </Box>

        <IconButton
          color="secondary"
          aria-label="close"
          onClick={(e) => {
            e.stopPropagation();

            setIsModal(false);
          }}
          sx={{
            alignSelf: 'start',
            width: '48px',
            height: '48px',
            backgroundColor:
              popupInfo?.colorMode === 'light'
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
            '&:hover': {
              backgroundColor:
                popupInfo?.colorMode === 'light'
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            },
            '&:active': {
              backgroundColor:
                popupInfo?.colorMode === 'light'
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            },
          }}
        >
          <Close
            stroke={
              popupInfo?.colorMode === 'light'
                ? theme.palette.text.secondary
                : theme.palette.text.primary
            }
            onClick={(e) => {
              e.stopPropagation();
              setIsModal(false);
            }}
            style={{
              cursor: 'pointer',
            }}
          />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default ServicePopup;
