import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';
import { theme } from 'theme';

import styles from './styles.module.scss';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import NewsCard from 'components/main/news/NewsCard';

import { ReactComponent as Blog } from 'assets/main/news/Blog.svg';
import { ReactComponent as News } from 'assets/main/news/News.svg';
import { ReactComponent as Pencil } from 'assets/main/news/Pencil.svg';

import { news } from './consts';

const NewsPage = () => {
  const { smXl, xlUp } = useMediaSize();
  return (
    <Box
      id="news"
      sx={{
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: '1293px',
          py: '140px',
          px: '24px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Pencil
          style={{
            position: 'absolute',
            top: '58px',
            left: '-190px',
          }}
        />
        <Blog
          style={{
            position: 'absolute',
            top: '17px',
            right: '1px',
            maxWidth: '848px',
            width: '100%',
          }}
        />
        <News
          style={{
            position: 'absolute',
            bottom: '114px',
            left: '8px',
            maxWidth: '439px',
            width: '100%',
          }}
        />
        <CustomButton
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '70px',
            width: '158px',
            height: '56px',
            borderRadius: '30px',
            backgroundColor: theme.palette.black.main,
            color: theme.palette.text.primary,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
          }}
        >
          Load more
        </CustomButton>

        <Box
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              fontSize: '64px',
              fontWeight: 800,
              lineHeight: '96px',
              textAlign: 'left',
              mb: '54px',
              color: theme.palette.text.secondary,
            }}
          >
            Blog and news
          </Typography>
          <Box
            className={
              xlUp ? styles.gridWrapperXL : smXl ? styles.gridWrapperSM : styles.gridWrapperXS
            }
          >
            {news.map((item) => (
              <Box
                key={item.id}
                className={`${styles[`news_card_${item.id}`]} news_card_${item.id}`}
              >
                <NewsCard news={item} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsPage;
