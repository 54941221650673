import ClutchPng from 'assets/main/first/Clutch.png';

export interface FirstInfo {
  id: number;
  number: string;
  text: string;
  image?: string;
}

export const firstInfo: FirstInfo[] = [
  {
    id: 1,
    image: ClutchPng,
    number: '',
    text: '',
  },
  {
    id: 2,
    number: '10+',
    text: 'Years of Experience',
  },
  {
    id: 3,
    number: '1200+',
    text: 'Projects Completed',
  },
];
