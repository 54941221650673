import { CSSProperties } from 'react';

import { SxProps } from '@mui/material';

import PodcastHosting from 'assets/main/awards/PodcastHosting.gif';
import SecondPlace from 'assets/main/awards/SecondPlace.webp';
import SupportingSustainability from 'assets/main/awards/SupportingSustainability.webp';
import ThirtyPlus from 'assets/main/awards/ThirtyPlus.webp';
import TopMetaverseInnovators from 'assets/main/awards/TopMetaverseInnovators.webp';
import TrendMonitoring from 'assets/main/awards/TrendMonitoring.webp';
import WinnersGears from 'assets/main/awards/WinnersGears.webp';
import WinnersMetaverse from 'assets/main/awards/WinnersMetaverse.webp';

export interface Awards {
  id: number;
  title: string;
  description: string;
  image?: string;
  json?: string;
  mainBoxSX?: SxProps;
  imageBoxSX?: SxProps;
  textBoxSX?: SxProps;
  jsonStyle?: CSSProperties;
  textSX?: SxProps;
  textTitleSX?: SxProps;
}

export const awards: Awards[] = [
  {
    id: 1,
    title: 'Best Use of Technology',
    description: 'NEAR Blockchain Hackathon: Recognized for top blockchain implementation',
    json: 'BestUseOfTechnology.json',
    jsonStyle: {
      maxWidth: '415px',
      width: '90%',
      position: 'absolute',
      bottom: '-65px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 2,
    title: 'Top 100 Metaverse Innovators 2022',
    description: 'Ranked among top metaverse innovators',
    image: TopMetaverseInnovators,
    mainBoxSX: {
      flexDirection: 'column-reverse',
      maxWidth: '630px',
      overflow: 'visible',
    },
    imageBoxSX: {
      maxWidth: '519px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '115px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 3,
    title: '2nd place',
    description: 'NEAR Blockchain Hackathon: Second place for innovative NEAR solutions',
    image: SecondPlace,
    imageBoxSX: {
      maxWidth: '246px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '80px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 4,
    title: '24+ Events Annually',
    description: 'Participating in 24+ industry events yearly',
    mainBoxSX: {
      maxWidth: '630px',
      height: '778px',
    },
    json: 'EventsAnnually.json',
    jsonStyle: {
      maxWidth: '590px',
      width: '100%',
      position: 'absolute',
      bottom: '24px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 5,
    title: 'Winners',
    description: 'DApp Hack 1.0 Hackathon: Community support and expert mentorship',
    image: WinnersGears,
    imageBoxSX: {
      maxWidth: '274px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '0px',
      left: '24px',
    },
    textBoxSX: { width: '100%' },
  },
  {
    id: 6,
    title: 'Winners',
    description: 'Intema Metaverse Accelerator 2023: Metaverse startup competition winner',
    image: WinnersMetaverse,
    imageBoxSX: {
      maxWidth: '242px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '81px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 7,
    title: 'Hackathons',
    description: 'Over 30 hackathons focused on innovation',
    image: ThirtyPlus,
    imageBoxSX: {
      maxWidth: '246px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '80px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 8,
    title: 'Winners',
    description: 'Blockchain GOLOS Hackathon: Best decentralized tech integration',
    json: 'Winners.json',
    jsonStyle: {
      maxWidth: '275px',
      width: '100%',
      position: 'absolute',
      bottom: '0px',
      right: '0px',
    },
  },
  {
    id: 9,
    title: 'Trend Monitoring',
    description: 'Tracking blockchain and Web3 trends',
    image: TrendMonitoring,
    imageBoxSX: {
      maxWidth: '296px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 10,
    title: 'Top 100 Metaverse Innovators 2022',
    description: 'Ranked among top metaverse innovators',
    image: SupportingSustainability,
    mainBoxSX: {
      flexDirection: 'column-reverse',
      maxWidth: '630px',
      overflow: 'visible',
    },
    imageBoxSX: {
      maxWidth: '630px',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '0px',
      borderRadius: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  {
    id: 11,
    title: 'Podcast Hosting',
    description: 'Hosting podcasts with industry leaders',
    image: PodcastHosting,
    imageBoxSX: {
      width: '100%',
      height: 'auto',
      position: 'absolute',
      bottom: '44px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
];
