interface Subjects {
  id: number;
  title: string;
}

export const subjects: Subjects[] = [
  {
    id: 1,
    title: 'Custom software',
  },
  {
    id: 2,
    title: 'Mobile App',
  },
  {
    id: 3,
    title: 'UX/UI',
  },
  {
    id: 4,
    title: 'Web development',
  },
];
