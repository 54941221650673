import React, { useEffect, useRef, useState } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import FlyingBall from 'components/common/FlyingBall';
import { GotAProjectDrawer } from 'components/common/GotAProject';
import FirstCard from 'components/main/first/FirstCard/FirstCard';

import { firstInfo } from './consts';

const FirstSection = () => {
  const [openGotAProjectDrawer, setOpenGotAProjectDrawer] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null); // Ссылка на элемент видео
  const [isVideoReady, setIsVideoReady] = useState(false); // Состояние готовности видео
  const videoSrc = '/bgVideo.mp4';
  const handleVideoLoaded = async () => {
    setIsVideoReady(true);
    await videoRef.current?.play();
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadeddata', handleVideoLoaded);

      return () => {
        videoElement.removeEventListener('loadeddata', handleVideoLoaded);
      };
    }
  }, []);
  return (
    <Box
      id="first"
      sx={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          height: '100vh',
          width: '100%',
          margin: 'auto',
          boxSizing: 'border-box',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {!isVideoReady && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              position: 'absolute',
              width: '100%',
              zIndex: 1,
              backgroundColor: theme.palette.black.main,
            }}
          ></Box>
        )}
        <Box
          component="video"
          ref={videoRef}
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: isVideoReady ? 0 : -1,
            opacity: isVideoReady ? 0.8 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
          src={videoSrc}
          autoPlay
          muted
          playsInline
          loop
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-40%)',
            maxWidth: '1290px',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '119px',
          }}
        >
          <FlyingBall setOpenGotAProjectDrawer={setOpenGotAProjectDrawer} />
          <Typography
            sx={{
              fontSize: '126px',
              fontWeight: 800,
              lineHeight: '146px',
              textAlign: 'left',
              color: theme.palette.text.primary,
            }}
          >
            Your
            <span style={{ color: theme.palette.orange.main }}> Trusted partner </span>
            for Digital Growth!
          </Typography>
          <Box sx={{ display: 'flex', gap: '30px' }}>
            {firstInfo.map((item) => (
              <FirstCard key={item.id} firstInfo={item} />
            ))}
          </Box>
        </Box>
      </Box>
      <GotAProjectDrawer
        openGotAProjectDrawer={openGotAProjectDrawer}
        setOpenGotAProjectDrawer={setOpenGotAProjectDrawer}
      />
    </Box>
  );
};
export default FirstSection;
