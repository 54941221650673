import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'theme';
import { scrollHandler } from 'utils/scrollHandler';

import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

import { ReactComponent as LogoBlack } from 'assets/common/LogoBlack.svg';
import { ReactComponent as Logo } from 'assets/common/logo.svg';

import { CustomButton } from '../Button';
import DropDownMenu from './DropDownMenu';
import { appbarLinks, sections } from './consts';

const CustomAppBar = () => {
  const navigate = useNavigate();
  const [showAppBar, setShowAppBar] = useState(true);
  const lastScrollY = useRef(window.scrollY);
  const [isBackground, setIsBackgroundVisible] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const pathname = useLocation().pathname;
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
      setShowAppBar(false);
      setAnchorEl(null);
    } else {
      setShowAppBar(true);
    }
    lastScrollY.current = currentScrollY;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    if (scrollTop < 1) {
      setIsBackgroundVisible(false);
    } else {
      setIsBackgroundVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.getAttribute('id');
            setActiveSection(sectionId);
          }
        });
      },
      { threshold: 0.6 }
    );
    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        observer.observe(element);
      }
    });
    return () => {
      sections.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);
  const isNews = pathname === '/news' && !isBackground;
  return (
    <AppBar
      sx={{
        position: 'fixed',
        transition: 'top 0.4s ease, background 0.5s ease',
        top: showAppBar ? '0' : '-100px',
        background: isBackground ? theme.palette.black.main : 'transparent',
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          maxWidth: '1290px',
          width: '100%',
          height: '100px',
          margin: 'auto',
          boxSizing: 'border-box',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isNews ? (
          <LogoBlack
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          />
        ) : (
          <Logo
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          />
        )}

        <Box
          sx={{
            width: '495px',
            height: '56px',
            backdropFilter: 'blur(20px)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: '16px',
            backgroundColor: '#FFFFFF33',
            border: `1px solid ${!isNews ? '#FFFFFF80' : theme.palette.text.secondary}`,
            borderRadius: '30px',
          }}
        >
          {appbarLinks.map((item) => (
            <Typography
              key={item.text}
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
                textAlign: 'center',
                cursor: 'pointer',
                color:
                  item.link === activeSection
                    ? theme.palette.orange.main
                    : !isNews
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
              }}
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                scrollHandler(e, item.link);
              }}
            >
              {item.text}
            </Typography>
          ))}

          <DropDownMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            activeSection={activeSection}
          />
        </Box>
        <CustomButton
          sx={{
            width: '144px',
            height: '56px',
            borderRadius: '30px',
            backgroundColor: !isNews ? theme.palette.text.primary : theme.palette.text.secondary,
            color: !isNews ? theme.palette.text.secondary : theme.palette.text.primary,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
          }}
        >
          Contact us
        </CustomButton>
      </Box>
    </AppBar>
  );
};

export default CustomAppBar;
