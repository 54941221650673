import React, { FC } from 'react';
import { Testimonials } from 'types';

import StarIcon from '@mui/icons-material/Star';
import { Box, Rating, Typography, useTheme } from '@mui/material';

import { ReactComponent as BottomQuotes } from 'assets/main/testimonials/BottomQuotes.svg';
import { ReactComponent as LnIcon } from 'assets/main/testimonials/LnIcon.svg';
import { ReactComponent as TopQuotes } from 'assets/main/testimonials/TopQuotes.svg';

interface IProps {
  testimonials: Testimonials;
}

const TestimonialsCard: FC<IProps> = ({ testimonials }) => {
  const { avatar, name, job, bodyFirst, bodySecond, position, rating, createdAt } = testimonials;
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '520px',
        height: '529px',
        borderRadius: '20px',
        border: '1px solid #BCBCBC',
        p: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                width: '394px',
                minHeight: '124px',
              }}
            >
              <Box
                component="img"
                src={avatar}
                alt="avatar"
                sx={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'start',
                  alignItems: 'left',
                  gap: '4px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '36px',
                    textAlign: 'left',
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  {job}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  {position}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Rating
                    name="text-feedback"
                    value={rating}
                    readOnly
                    precision={0.5}
                    sx={{ color: '#FF7A01' }}
                    icon={<StarIcon fontSize="inherit" />}
                    emptyIcon={<StarIcon style={{ opacity: 1 }} fontSize="large" />}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      textAlign: 'left',
                      color: theme.palette.grey[600],
                    }}
                  >
                    {createdAt}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <TopQuotes
              style={{
                marginTop: '29px',
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '38.4px',
                textAlign: 'left',
                fontFamily: 'Caveat, Arial, sans-serif',
              }}
            >
              {bodyFirst}
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                textAlign: 'left',
              }}
            >
              {bodySecond}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <BottomQuotes />
        <LnIcon />
      </Box>
    </Box>
  );
};

export default TestimonialsCard;
