import React, { Dispatch, FC, SetStateAction } from 'react';
import { theme } from 'theme';
import { scrollHandler } from 'utils/scrollHandler';

import { Box, IconButton, Menu, Typography } from '@mui/material';

import { ReactComponent as ArrowDown } from 'assets/common/ArrowDown.svg';

import { appbarDropdownLinks } from '../consts';

interface IProps {
  activeSection: string | null;
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

const DropDownMenu: FC<IProps> = ({ activeSection, anchorEl, setAnchorEl }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            textAlign: 'center',
            cursor: 'pointer',
            color:
              activeSection && appbarDropdownLinks.map((item) => item.link).includes(activeSection)
                ? theme.palette.orange.main
                : theme.palette.text.primary,
          }}
        >
          About
        </Typography>
        <IconButton edge="end" size="small" color="secondary" aria-label="SettingsIcon">
          {
            <ArrowDown
              style={{
                transition: '0.5s',
                transform: anchorEl ? 'rotate(180deg)' : 'none',
              }}
            />
          }
        </IconButton>
      </Box>
      <Menu
        disableScrollLock={true}
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            mt: '27px',
            borderRadius: '30px',
            backgroundColor: 'rgba(30,28,28,0.2)',
            backdropFilter: 'blur(20px)',
            width: '219px',
            ml: '20px',
            border: '1px solid #FFFFFF80',
          },
        }}
      >
        <Box
          onMouseLeave={() => setAnchorEl(null)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxSizing: 'border-box',
            gap: '0px',
            padding: '8px',
          }}
        >
          {appbarDropdownLinks.map((item) => (
            <Box
              key={item.text}
              onClick={(e) => {
                setAnchorEl(null);
                scrollHandler(e, item.link);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                width: '100%',
                height: '40px',
                p: '8px 16px',
                boxSizing: 'border-box',
                color:
                  item.link === activeSection
                    ? theme.palette.orange.main
                    : theme.palette.text.primary,
                '&:hover': {
                  color: theme.palette.text.secondary,
                  background: theme.palette.text.primary,
                  borderRadius: '20px',
                },
              }}
            >
              <Typography
                key={item.text}
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                {item.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Menu>
    </>
  );
};

export default DropDownMenu;
