import gsap from 'gsap';

const boxWidth = () => {
  const divWidth: number =
    document.querySelector('.howDoWeDo_section_full')?.clientWidth || window.innerWidth * 3;
  return divWidth > 4950 ? divWidth : 4950;
};

export const howDoWeDoAnimation = () => {
  // gsap.fromTo(
  //   '.howDoWeDo_section_full',
  //   {
  //     x: 0,
  //   },
  //   {
  //     scrollTrigger: {
  //       trigger: '.howDoWeDo_section',
  //       start: 'center center',
  //       end: '2000 top',
  //       scrub: 2,
  //       pin: true,
  //       invalidateOnRefresh: true,
  //     },
  //     x: () => {
  //       return -(boxWidth() - window.innerWidth);
  //     },
  //   }
  // );
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.howDoWeDo_section',
      start: 'center center',
      end: '2000 top',
      scrub: 2,
      pin: true,
      invalidateOnRefresh: true,
    }
  });

  tl.to('.howDoWeDo_section_full', {
    x: () => -(boxWidth() - window.innerWidth),
    delay: 0.1
  });
};
