import { ReactComponent as DiscoverIcon } from 'assets/main/process/DiscoverIcon.svg';
import { ReactComponent as InventIcon } from 'assets/main/process/InventIcon.svg';
import { ReactComponent as DesignIcon } from 'assets/main/process/DesignIcon.svg';
import { ReactComponent as ConstructIcon } from 'assets/main/process/ConstructIcon.svg';
import { ReactComponent as DeployIcon } from 'assets/main/process/DeployIcon.svg';
import { FunctionComponent, SVGProps } from 'react';

export interface IProcess {
  id:number
  title: string;
  firstDescription: string;
  secondDescription: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  percent:number
}

export const process: IProcess[] = [
  {
    id:1,
    title: 'Discover',
    firstDescription: 'We start by analyzing the problem and its context to uncover key issues',
    secondDescription: 'This allows us to define a clear task, project charter, and strategy for solving the problem',
    icon:DiscoverIcon,
    percent:48
  },
  {
    id:2,
    title: 'Invent',
    firstDescription: 'We create innovative solutions using industry best practices and cutting-edge technologies',
    secondDescription: 'At this stage, we develop a clear problem description, technical requirements, and the scope of the product',
    icon:InventIcon,
    percent:21
  },
  {
    id:3,
    title: 'Design',
    firstDescription: 'The design phase helps avoid errors and control costs',
    secondDescription: 'We create product prototypes and hold workshops with stakeholders to ensure the solution meets all requirements',
    icon:DesignIcon,
    percent:10
  },
  {
    id:4,
    title: 'Construct',
    firstDescription: 'With a well-defined scope and confirmed design, our experts begin product development',
    secondDescription: 'We build a solid architecture that ensures high quality, reduces costs, and accelerates the product’s time to market. Security is audited throughout the process',
    icon:ConstructIcon,
    percent:-1
  },
  {
    id:5,
    title: 'Deploy',
    firstDescription: 'We ensure a smooth product launch and integration into your business processes',
    secondDescription: 'Penetration testing and security audits are performed. Your product is ready for use, fully secured, and integrated into your business',
    icon:DeployIcon,
    percent:-12

  },
];