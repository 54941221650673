import React from 'react';
import { theme } from 'theme';

import { Box } from '@mui/material';

import HowDoWeDoCard from 'components/main/how-do-we-do/HowDoWeDoCard';

import { ReactComponent as HowDoWeDoIT } from 'assets/main/how-do-we-do/HowDoWeDoIT.svg';

import { howDoWeDo } from './consts';

const HowWeDoWeDoIt = () => {
  return (
    <Box
      id="process"
      className={'howDoWeDo_section'}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.black.main,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2,
        }}
      >
        <HowDoWeDoIT
          style={{
            position: 'absolute',
            top: '70px',
            right: '0px',
            maxWidth: '1671px',
            width: '100%',
          }}
        />
      </Box>

      <Box className={'howDoWeDo_section_full'} sx={{ display: 'flex', width: '300vw' }}>
        {howDoWeDo.map((item) => (
          <HowDoWeDoCard key={item.id} howDoWeDo={item} />
        ))}
      </Box>
    </Box>
  );
};

export default HowWeDoWeDoIt;
