import LinkedInPng from 'assets/common/LinkedIn.png';
import TiktokPng from 'assets/common/Tiktok.png';
import YoutubePng from 'assets/common/Youtube.png';

interface Social {
  link: string;
  icon: string;
}

interface Policies {
  link: string;
  title: string;
}

interface IPage {
  text: string;
  link: string;
}

export const pages: IPage[] = [
  { text: 'Services', link: 'services' },
  { text: 'Process', link: 'process' },
  { text: 'Portfolio', link: 'portfolio' },
  { text: 'Testimonials and Team', link: 'testimonials' },
  { text: 'Awards and Trends', link: 'awards' },
  { text: 'Blog and news', link: 'news' },
];

export const social: Social[] = [
  {
    link: '"https://www.youtube.com/',
    icon: YoutubePng,
  },
  {
    link: '"https://www.tiktok.com/',
    icon: TiktokPng,
  },
  {
    link: 'https://www.linkedin.com/in/vitaliyopryshko/',
    icon: LinkedInPng,
  },
];

export const policies: Policies[] = [
  {
    link: '/privacy-policy',
    title: 'Privacy Policy',
  },
  {
    link: '"/terms-conditions',
    title: 'Terms of Service',
  },
  {
    link: '/cookies-settings',
    title: 'Cookies Settings',
  },
];
