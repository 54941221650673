import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';
import { theme } from 'theme';

import styles from './styles.module.scss';

import { Box, Typography } from '@mui/material';

import AwardsCard from 'components/main/awards/AwardsCard';

import { ReactComponent as Awards } from 'assets/main/awards/Awards.svg';
import Cup from 'assets/main/awards/Cup.webp';
import { ReactComponent as TUpper } from 'assets/main/awards/TUpper.svg';
import { ReactComponent as D } from 'assets/main/awards/d.svg';
import { ReactComponent as E } from 'assets/main/awards/e.svg';
import { ReactComponent as N } from 'assets/main/awards/n.svg';
import { ReactComponent as R } from 'assets/main/awards/r.svg';
import { ReactComponent as S } from 'assets/main/awards/s.svg';

import { awards } from './consts';

const AwardsSection = () => {
  const { smXl, xlUp } = useMediaSize();

  return (
    <Box
      id="awards"
      sx={{
        width: '100%',
        overflowX: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.black.main,
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: '2007px',
          py: '140px',
          px: '24px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src={Cup}
          sx={{
            position: 'absolute',
            bottom: '650px',
            right: '-92px',
            zIndex: 0,
          }}
        />
        <Awards
          style={{
            position: 'absolute',
            top: '45px',
            left: '3px',
            maxWidth: '1722px',
            width: '100%',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            position: 'absolute',
            bottom: '57.68px',
            left: '17px',
            maxWidth: '1722px',
            width: '100%',
            height: '200px',
          }}
        >
          <TUpper style={{ marginRight: '6.79px' }} />
          <R style={{ marginRight: '12.55px' }} />
          <E style={{ marginRight: '21.31px', zIndex: 3 }} />
          <N style={{ marginRight: '23.13px' }} />
          <D style={{ marginRight: '22.38px' }} />
          <S style={{ marginRight: '82px' }} />
        </Box>

        <Box
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              fontSize: '64px',
              fontWeight: 800,
              lineHeight: '96px',
              textAlign: 'left',
              mb: '54px',
              color: theme.palette.text.primary,
            }}
          >
            Awards and Trends
          </Typography>
          <Box
            className={
              xlUp ? styles.gridWrapperXL : smXl ? styles.gridWrapperSM : styles.gridWrapperXS
            }
          >
            {awards.map((award) => (
              <Box
                key={award.id}
                className={`${styles[`awards_card_${award.id}`]} awards_card_${award.id}`}
              >
                <AwardsCard award={award} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AwardsSection;
