import { FC } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { News } from 'pages/news/consts';

interface IProps {
  news: News;
}

const NewsCard: FC<IProps> = ({ news }) => {
  const { image, title, createdAt, newsType, mainBoxSX, imageBoxSX } = news;
  return (
    <Box
      sx={{
        maxWidth: '410px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        alignSelf: 'flex-start',
        ...mainBoxSX,
      }}
    >
      <Box sx={{ overflow: 'hidden', borderRadius: '20px', ...imageBoxSX }}>
        <Box className="news_card_img">
          <Box
            component="img"
            src={image}
            sx={{
              transition: 'transform 0.3s ease-in-out',
              cursor: 'pointer',
              '&:hover': {
                transform: `scale(1.1)`,
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '4px',
          color: '#A3A3A3',
          mb: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            position: 'relative',
          }}
        >
          {newsType}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'right',
            position: 'relative',
          }}
        >
          {createdAt}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 500,
          lineHeight: '36px',
          textAlign: 'left',
          color: theme.palette.text.secondary,
          mb: '38px',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default NewsCard;
