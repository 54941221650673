import gsap from 'gsap';

export const whoWeAreAnimation = () => {
  gsap.fromTo(
    '.wwa_section_photo',
    {
      x: 0,
    },
    {
      scrollTrigger: {
        trigger: '.wwa_section',
        start: 'center center',
        end: '2000 top',
        scrub: 1.5,
        pin: true,
        invalidateOnRefresh: true,
      },
      x: () => {
        return -(4440 - window.innerWidth);
      },
    }
  );
  gsap.fromTo(
    '.wwa_section_text',
    {
      x: 0,
    },
    {
      scrollTrigger: {
        trigger: '.wwa_section',
        start: 'center center',
        end: '2000 top',
        scrub: 1.5,
        invalidateOnRefresh: true,
      },
      x: () => {
        return -(3840 - window.innerWidth);
      },
    }
  );
};
