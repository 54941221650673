import gsap from 'gsap';

export const portfolioAnimation = ()=>{
  gsap.fromTo(
    '.portfolio_section_top_row',
    { xPercent: 70 },
    {
      scrollTrigger: {
        trigger: '.portfolio_section',
        start: '200 bottom',
        end: 'bottom top',
        scrub: 1.5,
        invalidateOnRefresh: true,
      },
      xPercent: -20,
    }
  );
  gsap.fromTo(
    '.portfolio_section_bottom_row',
    { xPercent: -70 },
    {
      scrollTrigger: {
        trigger: '.portfolio_section',
        start: '200 bottom',
        end: 'bottom top',
        scrub: 1.5,
        invalidateOnRefresh: true,
      },
      xPercent: 20,
    }
  );
}