import React, { FC } from 'react';
import { theme } from 'theme';
import { Portfolio } from 'types';

import { Box, Typography } from '@mui/material';

interface IProps {
  portfolio: Portfolio;
}

const PortfolioPageCard: FC<IProps> = ({ portfolio }) => {
  const { title, description, image } = portfolio;
  return (
    <Box
      sx={{
        minHeight: '668px',
        width: '630px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        gap: '16px',
      }}
    >
      <Box
        sx={{
          width: '630px',
          height: '554px',
          borderRadius: '20px',
          overflow: 'hidden',
          border: '1px solid #FFFFFF80',
        }}
      >
        <Box
          component="img"
          src={image}
          alt="image"
          sx={{
            width: '630px',
            height: 'auto',
            backgroundColor: '#1B1B1B',
            transition: '0.5s ease',
            '&:hover': {
              transform: `scale(1.05)`,
              zIndex: 11,
              transition: '0.5s ease',
            },
          }}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '36px',
            textAlign: 'left',
            color: theme.palette.text.primary,
            mb: '8px',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: theme.palette.text.primary,
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default PortfolioPageCard;
