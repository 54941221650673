import React, { FC } from 'react';
import { theme } from 'theme';
import { Portfolio } from 'types';

import { Box, Typography } from '@mui/material';

interface IProps {
  portfolio: Portfolio;
}

const PortfolioCard: FC<IProps> = ({ portfolio }) => {
  const { title, description, image } = portfolio;
  return (
    <Box
      sx={{
        height: '500px',
        width: '410px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        gap: '16px',
      }}
    >
      <Box
        component="img"
        src={image}
        alt="image"
        sx={{
          width: '410px',
          height: 'auto',
          backgroundColor: '#1B1B1B',
          borderRadius: '20px',
          border: '1px solid #FFFFFF80',
        }}
      />
      <Box>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '32.78px',
            textAlign: 'left',
            color: theme.palette.text.primary,
            mb: '8px',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
            textAlign: 'left',
            color: theme.palette.text.primary,
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default PortfolioCard;
