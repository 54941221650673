import React, { useState } from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import { CustomButton } from 'components/common/Button';
import PortfolioCard from 'components/portfolio/PortfolioPageCard';

import { ReactComponent as Circlet } from 'assets/portfolio/Circlet.svg';
import { ReactComponent as OurWorks } from 'assets/portfolio/OurWorks.svg';
import { ReactComponent as Palette } from 'assets/portfolio/Palette.svg';
import { ReactComponent as Pen } from 'assets/portfolio/Pen.svg';

import { portfolioBottomRow, portfolioThirdRow, portfolioTopRow } from './consts';

const PortfolioPage = () => {
  const [portfolioPortion, setPortfolioPortion] = useState<number>(1);
  return (
    <Box
      id="portfolio_page"
      sx={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.black.main,
      }}
    >
      <Box
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          minHeight: '2016px',
          p: '220px 24px 150px 24px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <OurWorks
          style={{
            position: 'absolute',
            top: '82px',
            right: '0px',
            maxWidth: '1445px',
            width: '100%',
          }}
        />
        <Circlet
          style={{
            position: 'absolute',
            top: '889px',
            left: '0px',
          }}
        />
        <Palette
          style={{
            position: 'absolute',
            top: '3440px',
            right: '-63px',
          }}
        />
        <Pen
          style={{
            position: 'absolute',
            top: '4457px',
            left: '0px',
          }}
        />

        <Box
          sx={{
            position: 'relative',
            maxWidth: { sm: '900px', xl: '1290px' },
            width: '100%',
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              fontSize: '64px',
              fontWeight: 800,
              lineHeight: '96px',
              textAlign: 'left',
              mb: '54px',
              color: theme.palette.text.primary,
            }}
          >
            Portfolio
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '30px',
              justifyContent: 'center',
            }}
          >
            {portfolioTopRow.map((card) => (
              <PortfolioCard key={card.id} portfolio={card} />
            ))}
            {portfolioPortion > 1 &&
              portfolioBottomRow.map((card) => <PortfolioCard key={card.id} portfolio={card} />)}
            {portfolioPortion > 2 &&
              portfolioThirdRow.map((card) => <PortfolioCard key={card.id} portfolio={card} />)}
          </Box>
          {portfolioPortion < 3 && (
            <CustomButton
              sx={{
                margin: 'auto',
                mt: '32px',
                width: '182px',
                height: '56px',
                borderRadius: '30px',
                backgroundColor: theme.palette.orange.main,
                color: theme.palette.text.secondary,
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '24px',
              }}
              onClick={() => {
                setPortfolioPortion((prev) => prev + 1);
              }}
            >
              View all projects
            </CustomButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioPage;
