import React from 'react';
import { theme } from 'theme';

import { Box, Typography } from '@mui/material';

import ProcessCard from 'components/main/process/ProcessCard';

import Construct from 'assets/main/process/Construct.png';
import Deploy from 'assets/main/process/Deploy.png';
import Design from 'assets/main/process/Design.png';
import Discover from 'assets/main/process/Discover.png';
import Invent from 'assets/main/process/Invent.png';
import { ReactComponent as Mouse } from 'assets/main/process/Mouse.svg';
import { ReactComponent as Pliers } from 'assets/main/process/Pliers.svg';
import { ReactComponent as Process } from 'assets/main/process/Process.svg';

import { process } from './consts';

const images = [
  {
    id: 1,
    image: Discover,
  },
  {
    id: 2,
    image: Invent,
  },
  {
    id: 3,
    image: Design,
  },
  {
    id: 4,
    image: Construct,
  },
  {
    id: 5,
    image: Deploy,
  },
];

const ProcessSection = () => {
  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'hidden',
      }}
    >
      <Box
        id="process"
        className="process_section"
        sx={{
          maxWidth: '1920px',
          width: '100%',
          margin: 'auto',
          py: { xs: '7vw', xxxl: '140px' },
          px: '75px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            '@media(max-height:1000px)': { top: '40px' },
            right: '0px',
            zIndex: -1,
          }}
        >
          <Process
            style={{
              maxWidth: '989px',
              width: '100%',
            }}
          />
        </Box>

        <Pliers
          style={{
            position: 'absolute',
            top: '232px',
            left: '-94px',
            zIndex: -1,
          }}
        />
        <Mouse
          style={{
            position: 'absolute',
            bottom: '86px',
            right: '0px',
            zIndex: -1,
          }}
        />
        <Box
          sx={{
            position: 'relative',
            maxWidth: '1290px',
            width: '100%',
            margin: 'auto',
          }}
        >
          <Typography
            sx={{
              fontSize: '64px',
              fontWeight: 800,
              lineHeight: '96px',
              textAlign: 'left',
              mb: '54px',
            }}
          >
            Process
          </Typography>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              gap: '30px',
              height: '650px',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'end',
                p: '24px',
                minWidth: '520px',
                flex: 0.39,
                borderRadius: '20px',
                backgroundColor: theme.palette.black.main,
                overflow: 'hidden',
                gap: '8px',
                boxSizing: 'border-box',
              }}
            >
              {process.map((process) => (
                <ProcessCard key={process.id} process={process} />
              ))}
            </Box>

            <Box
              sx={{
                height: '650px',
                display: 'flex',
                flex: 0.61,
                borderRadius: '24px',
                position: 'relative',
                backgroundColor: 'white',
              }}
            >
              {images.map((image) => (
                <Box
                  className={`process_image_${image.id}`}
                  key={image.id}
                  component="img"
                  src={image.image}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translateX(-50%) translateY(-50%)',
                    width: '101%',
                    height: 'auto',
                    opacity: image.id === 0 ? 1 : 0,
                    transition: 'all 0.25s linear',
                    borderRadius: '24px',
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProcessSection;
