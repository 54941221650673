import { Testimonials } from 'types';

import avatar from 'assets/main/testimonials/avatar.png';

export const testimonials: Testimonials[] = [
  {
    id: 1,
    name: 'George Budd',
    job: 'Chief Software Architect, GjBudd Designs LLC',
    position: 'Custom Software Development for Software Company',
    avatar,
    bodyFirst:
      '“The team impressed us with their promptness and  competence in solving a wide variety of problems”',
    bodySecond:
      ' “We Can Develop It delivered the project on time and met all requirements. The  team quickly implemented changes, allowing for flexibility in response to customer  needs. Accessible via email and virtual meetings, they offer professional and  competent services”',
    rating: 5,
    createdAt: 'May 13, 2024',
  },
  {
    id: 2,
    name: 'Lorem 2 ',
    job: 'Lorem ipsum ',
    position: 'Custom Software Development for Software Company',
    avatar,
    bodyFirst:
      '“The team impressed us with their promptness and  competence in solving a wide variety of problems”',
    bodySecond:
      ' “We Can Develop It delivered the project on time and met all requirements. The  team quickly implemented changes, allowing for flexibility in response to customer  needs. Accessible via email and virtual meetings, they offer professional and  competent services”',
    rating: 5,
    createdAt: 'May 13, 2024',
  },
  {
    id: 3,
    name: 'Lorem 3 ',
    job: 'Lorem ipsum ',
    position: 'Custom Software Development for Software Company',
    avatar,
    bodyFirst:
      '“The team impressed us with their promptness and  competence in solving a wide variety of problems”',
    bodySecond:
      ' “We Can Develop It delivered the project on time and met all requirements. The  team quickly implemented changes, allowing for flexibility in response to customer  needs. Accessible via email and virtual meetings, they offer professional and  competent services”',
    rating: 5,
    createdAt: 'May 13, 2024',
  },
  {
    id: 4,
    name: 'Lorem 4 ',
    job: 'Lorem ipsum ',
    position: 'Custom Software Development for Software Company',
    avatar,
    bodyFirst:
      '“The team impressed us with their promptness and  competence in solving a wide variety of problems”',
    bodySecond:
      ' “We Can Develop It delivered the project on time and met all requirements. The  team quickly implemented changes, allowing for flexibility in response to customer  needs. Accessible via email and virtual meetings, they offer professional and  competent services”',
    rating: 5,
    createdAt: 'May 13, 2024',
  },
  {
    id: 5,
    name: 'Lorem 5 ',
    job: 'Lorem ipsum ',
    position: 'Custom Software Development for Software Company',
    avatar,
    bodyFirst:
      '“The team impressed us with their promptness and  competence in solving a wide variety of problems”',
    bodySecond:
      ' “We Can Develop It delivered the project on time and met all requirements. The  team quickly implemented changes, allowing for flexibility in response to customer  needs. Accessible via email and virtual meetings, they offer professional and  competent services”',
    rating: 5,
    createdAt: 'May 13, 2024',
  },
];
